<template>
  <div class="blog-container">
    <Navigation/>
    <div class="blogs">
      <div id="blog-content">
        <div class="blog-title">
          {{blog.title}}
        </div>
        <div class="blog-info">
          <span v-for="category in categories" :key="category.id">
            <a :href="'/category/' + category.url">{{category.name}}</a>
            ,
          </span>
          <span>{{blog.date_published}}</span>
        </div>
        <img class="blog-image" :src="blog.image_url"/>
        <div class="blog-content" v-html="content"></div>
        <div class="blog-keywords">
          <span v-for="tag in tags" :key="tag">
            <a :href="'/tag/' + tag.trim()">{{tag}}</a>
            ,
          </span>
          <span>{{blog.date_published}}</span>
        </div>
      </div>
      <div class="blog-list">
        <div class="label">Popular Blogs</div>
        <div :class="b.id != blog.id ? 'blog':'blog hidden'" v-for="b in popularBlogs" :key="b.id">
          <a class="title" :href="`/blog/${b.url}`">{{b.title}}</a>
          <div class="desc">{{b.short_desc}}</div>
          <a class="link" :href="`/blog/${b.url}`">Read full article</a>
        </div>
      </div>
    </div>
    <BgTextree/>
  </div>
</template>

<script>
import store from '@/store'
import api from '@/api'

export default {
  props: {
    url: String
  },
  components: {
    Navigation: () => import('@/components/nav'),
    BgTextree: () => import('@/components/bg-textree')
  },
  data: () => ({
    blog: {
      categories: [],
      keywords: '',
      short_desc: ''
    }
  }),
  computed: {
    content: function () {
      return this.blog.content.replaceAll(/(height=")[0-9]*(")+/g, 'height="auto"')
    },
    categories: function () {
      var categories = []
      for (var cat of store.state.blog.categories) {
        if (this.blog && cat.shown) {
          if (this.blog.categories.includes(cat.id)) {
            categories.push(cat)
          }
        }
      }
      return categories
    },
    popularBlogs: function () {
      var blogs = store.state.blog.popularList
      if (blogs.length == 0) {
        api.blog.getPopularBlogs()
      }
      return blogs
    },
    tags: function () {
      var tgs = []
      if (this.blog.keywords) {
        tgs = this.blog.keywords.split(',')
      }
      return tgs
    }
  },
  mounted() {
    store.commit('SET_NAV_PAGE', 'blog')
    store.commit('SET_NAV_THEME', 'dark')

    this.getBlog()
  },
  methods: {
    getBlog: async function () {
      let blog = await api.blog.getBlog(this.url)
      if (blog.id) {
        this.blog = blog
      } else {
        this.$router.replace({
          name: 'NotFound',
          params: { 0: `/${this.$route.path}` },
          replace: true,
        })
      }
    }
  }
}
</script>
<style>
  blockquote {
    border-width: 0;
    border-style: solid;
  }
  div.blog-content img {
    max-width: 100%;
  }
</style>
<style scoped>
div.blog-container {
  padding: 24px;
  position: fixed;
  top: 76px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: scroll;
}

div.blogs {
  display: flex;
  margin: auto;
  max-width: calc(1600px - 256px);
}

div#blog-content {
  width: 70%;
  margin-right: 24px;
}

div.blog-title {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: bold;
  line-height: 64px;
  color: #2E2E2E;
}
div.blog-info {
  font-family: "NanumGothic";
  font-size: 14px;
  font-weight: bold;
  line-height: 32px;
  color: #959595;
}
img.blog-image {
  width: 100%;
  margin: 24px 0;
}
div.blog-content {
  padding-top: 24px;
}

div.blog-list {
  width: calc(30% - 48px);
  border-left: .5px solid #707070;
  margin-left: 24px;
  padding-left: 24px;
}
div.blog-list .label {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #2E2E2E;
  margin-bottom: 24px;
}
div.blog-list a {
  text-decoration: none;
}
div.blog-list .blog {
  margin-bottom: 24px;
}
div.blog.hidden {
  display: none;
}

div.blog-list .title {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
  color: #008ED6;
}
div.blog-list .desc {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  line-height: 2;
  color: #959595;
  padding: 6px 0;
  max-height: 104px;
  overflow-y: hidden;
}
div.blog-list .link {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  color: #F1B500;
}

@media(max-width: 1024px) {
  div.blog-list {
    width: 30%;
    margin-left: 0;
  }
  div.blog-title {
    line-height: 1.3;
  }
}

@media(max-width: 768px) {
  div.blog-title {
    font-size: 36px;
  }
}

@media(max-width: 576px) {
  div.blog-container {
    width: 100%;
    padding: 0 24px;
  }
  div.blog-title {
    font-size: 20px;
  }
  div.blogs {
    flex-direction: column;
  }
  div#blog-content {
    width: 100%;
    margin-right: 0;
  }

  div.blog-list {
    width: 100%;
    border-left: none;
    margin-top: 24px;
    padding-left: 0;
  }
  div.blog-list > * {
    padding: 24px 0 0 !important;
    width: 100% !important;
  }
}
</style>

<style>
@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 12px;
  }
}

@media(max-width: 768px) {
  #plusfriend-chat-button {
    bottom: 12px;
  }
}
</style>
